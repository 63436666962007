<template>
  <div :class="wrapperClass">
        <br>
        <img class="status" :src="require('../../assets/icons/status/' + image)"/>
        <Tag :rounded="false" :severity="severity">
            {{status}}
        </Tag>
    </div>
</template>

<script>
export default {
    props: {
        wrapperClass: {
            type: String,
            default: "p-field p-col-2"
        },
        severity: {
            type: String,
            default: "primary"
        },
        status: {
            type: String,
            default: "Aprobado"
        },
        image: {
            type: String,
            default: "afected.png"
        },
    }
}
</script>

<style>
    .status {
    width: 35px;
    margin-right: 1rem;
    }
</style>